<template>
    <v-row>
        <v-col>
            <!--            <home-header></home-header>-->


            fsdfsd
            <v-row>
                <v-col cols="6">
                    <img src="" alt="Logo"/>

                </v-col>

                <v-col>
                    <div class="rt-hover-heading ">
                        <h2 class="rt-title-heading"><span class="rt-highlight-txt">Features<span
                                class="rt-underline-txt">
                            <svg width="100%" height="22" viewBox="0 0 221 22" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
<path d="M3 18.9999C55 4.99993 147 -4.00008 218 11.9999" stroke="url(#paint0_linear_1_139)" stroke-width="6"
      stroke-linecap="round"></path>
<defs>
<linearGradient id="paint0_linear_1_139" x1="3" y1="18.9999" x2="231" y2="10.9999" gradientUnits="userSpaceOnUse">
<stop offset="100%" stop-color="#dc2626"></stop>
<stop offset="1" stop-color="#1e3a8a"></stop>
</linearGradient>
</defs>
</svg></span></span></h2>
                    </div>


                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
//import HomeHeader from "@/modules/home/HomeHeader.vue";

export default {
    name: "Features",
    /*components: {HomeHeader}*/
}
</script>

<style scoped>
h2 {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600 !important;
    text-transform: capitalize;
    font-size: 2.5em;
    line-height: 1.4em !important;
    color: #1d1a4e;
}

.rt-title-heading {
    position: relative;
}

.rt-highlight-txt {
    position: relative;
    display: inline-block;
}
</style>